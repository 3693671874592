import React, { Component } from "react"
import Link from "gatsby-link"

class Cookies extends Component {
  constructor(props) {
    super(props)
    this.state = {className: ''}
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    localStorage.setItem('accept', '1');
    this.setState(prevState => ({
      className: ''
    }))
  }

  componentDidMount() {
    if ((typeof window !== 'undefined' ? localStorage.getItem('accept') : false) !== '1') {
      this.setState(prevState => ({
        className: 'show'
      }))
    }
  }

  render() {
    return (
      <div className={`m-cookies ${this.state.className}`}>
        <div data-g="container" className="m-cookies__wrapper">
          <div className="m-cookies__description">Nasza strona używa plików cookies w celach statystycznych oraz dla ułatwienia korzystania z serwisu. Ustawienia te zawsze można zmienić.</div>
          <div className="m-cookies__links">
            <div className="m-cookies__know-more">
              <Link to={'/polityka-prywatnosci'}>Dowiedz się więcej</Link>
            </div>
            <div className="m-cookies__accept" onClick={this.handleClick}>Zaakceptuj</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Cookies

