import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby-link"

function SEO({ description, url, meta, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'pl',
      }}
      title={title}
      meta={[
        {
          charset: `UTF-8`,
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
        },
        {
          name: 'description',
          content: description
        },
        {
          name: 'og:locale',
          content: 'pl_PL'
        },
        {
          name: 'og:type',
          content: 'website'
        },
        {
          name: 'og:title',
          content: title
        },
        {
          name: 'og:description',
          content: description
        },
        {
          name: 'og:url',
          content: url
        },
        {
          name: 'og:site_name',
          content: title
        },
        {
          name: 'og:image',
          content: 'https://medicusdukla.pl/images/meta.png'
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          name: 'twitter:description',
          content: description
        },
        {
          name: 'twitter:title',
          content: title
        }
      ]}
      link={[
        {
          rel: "icon",
          type: 'image/x-icon',
          href: withPrefix('favicon.png'),
        },
        {
          rel: "preload",
          href: "/fonts/regular.woff2",
          as: "font",
          type: "font/woff2",
          crossorigin: "",
        },
        {
          rel: "preload",
          href: "/fonts/600.woff2",
          as: "font",
          type: "font/woff2",
          crossorigin: "",
        },
        {
          rel: "preload",
          href: "/fonts/700.woff2",
          as: "font",
          type: "font/woff2",
          crossorigin: "",
        },
        {
          rel: "preload",
          href: "/fonts/800.woff2",
          as: "font",
          type: "font/woff2",
          crossorigin: "",
        }
      ]}
    />
  )
}

export default SEO
