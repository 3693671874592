import React from "react"
import { StaticImage} from "gatsby-plugin-image"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="o-footer">
      <div data-g="container grid">
        <div data-g="4 5@md 4@lg" className="logo">
          <img className="a-img" src="/images/logo-biale.svg" alt="logo białe" quality={80}/>
          <ul className={"info"}>
            <li style={{display: 'inline-flex' }}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.75 7.5C15.75 12.75 9 17.25 9 17.25C9 17.25 2.25 12.75 2.25 7.5C2.25 5.70979 2.96116 3.9929 4.22703 2.72703C5.4929 1.46116 7.20979 0.75 9 0.75C10.7902 0.75 12.5071 1.46116 13.773 2.72703C15.0388 3.9929 15.75 5.70979 15.75 7.5Z" stroke="#FCFCFC" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z" stroke="#FCFCFC" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              ul. Trakt Węgierski 16, 38-450 Dukla
            </li>
            <li>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                  <path d="M11.6667 10.9788L9.66667 13.1038L4.33333 7.43717L6.33333 5.31217L3 1.77051L1 3.89551C1 10.937 6.37267 16.6455 13 16.6455L15 14.5205L11.6667 10.9788Z" stroke="#FCFCFC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="16" height="17" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              tel. 13 433 03 28
            </li>
            <li style={{display: 'flex'}}>
              <div>
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.99967 15.5837C11.6816 15.5837 14.6663 12.4123 14.6663 8.50033C14.6663 4.58831 11.6816 1.41699 7.99967 1.41699C4.31778 1.41699 1.33301 4.58831 1.33301 8.50033C1.33301 12.4123 4.31778 15.5837 7.99967 15.5837Z" stroke="#FCFCFC" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M8 4.25V8.5L10.6667 9.91667" stroke="#FCFCFC" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <div>
                Poniedziałek-piątek 8:00-18:00 <br/>
                Sobota 10:00-15:00
              </div>
            </li>
          </ul>
        </div>
        <div data-g="2 offset-8@md 1@md">
          <h4>Projekty</h4>
          <ul>
            <li><Link to="/ddom/">DDOM</Link></li>
            <li><Link to="/projekty-unijne/informatyzacja/">Informatyzacja</Link></li>
            <li><Link to="/projekty-unijne/wypozyczalnia/">Wypożyczalnia sprzętu</Link></li>
          </ul>
        </div>
        <div data-g="2 offset-10@md 1@md">
          <h4>Oferta</h4>
          <ul>
            <li><Link to="/uslugi/">Usługi</Link></li>
            <li><Link to="/diagnostyka/">Diagnostyka</Link></li>
            <li><Link to="/poradnie/">Poradnie</Link></li>
          </ul>
        </div>
        <div data-g="2 offset-12@md 1@md">
          <h4>Przychodnia</h4>
          <ul>
            <li><Link to="/o-nas/">O nas</Link></li>
            <li><Link to="/aktualnosci/">Aktualności</Link></li>
            <li><Link to="/apteki/">Apteki</Link></li>
          </ul>
        </div>
      </div>
      <div data-g={"container"}>
        <div data-g={"hide show@md"}>
          <div className="bottom">
            <div>
              © 2020 Medicus Dukla. Wszystkie prawa zastrzeżone.
              <span className="privacy"><Link to="/polityka-prywatnosci/">Polityka Prywatności i pliki cookies</Link></span>
            </div>
            <div className={"nip"}>
              <span>NIP 684-22-94-169</span>
              <span>REGON 3710914400 </span>
              <span>KRS0000102243</span>
            </div>
          </div>
        </div>
      </div>

      <div data-g="container grid" className={"bottom"}>
        <div data-g={"show hide@md 4"}>
          <div>
            <span>NIP 684-22-94-169 </span>
            <span>REGON 3710914400 </span>
            <span>KRS0000102243</span>
          </div>
          <div>
            © {new Date().getFullYear()} Medicus Dukla. Wszystkie prawa zastrzeżone.
          </div>
          <div>
            <Link to="/polityka-prywatnosci/">Polityka Prywatności i pliki cookies</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
