import React from "react"
import PropTypes from "prop-types"
import "../../styles/style.scss"
import Header from "./header"
import Footer from "./footer"
import Cookies from "../m-cookies"

const Layout = ({ children, marginTop }) => {
  console.log(marginTop)
  return (
    <div className="wrapper">
      <Header />
      <main style={{marginTop}}>
        {children}
      </main>
      <script
        type="application/ld+json">{`{"@context":"http:\/\/schema.org","@type":"WebSite","@id":"#website","url":"https:\/\/medicusdukla.pl\/","name":"Przychodnia specjalistyczna Medicus-Dukla. Prywatnie i NFZ","alternateName":"Poradnie: Podstawowa opieka zdrowotna \/ Poradnie specjalistyczne (NFZ) \/ Poradnie specjalistyczne prywatne. Diagnostyka. Usługi: Opieka Pielęgniarska Długoterminowa | Hospicjum domowe | Medycyna Pracy | Badania Kierowców i wszystkie kategorie | Transport sanitarny"}`}</script>
      <Footer />
      <Cookies />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
