import { withPrefix, Link } from "gatsby"
import React, { Component } from "react"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { active: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(prevState => ({
      active: !prevState.active
    }))
  }

  render() {
    const items = [
      { href: "/uslugi/", title: "Usługi", submenu: null },
      { href: "/aktualnosci/", title: "Aktualności", submenu: null },
      {
        href: "/diagnostyka/",
        title: "Diagnostyka",
        submenu: [
          { href: "/diagnostyka/audiometria/", title: "Audiometria" },
          { href: "/diagnostyka/ekg/", title: "EKG" },
          { href: "/diagnostyka/rtg/", title: "RTG" },
          { href: "/diagnostyka/densytometria/", title: "Densytometria" },
          { href: "/diagnostyka/pracownia-kardiologiczna/", title: "Pracownia kardiologiczna" },
          { href: "/diagnostyka/laboratorium/", title: "Laboratorium" },
          { href: "/diagnostyka/spirometria/", title: "Spirometria" },
          { href: "/diagnostyka/usg/", title: "USG" }
        ]
      },
      {
        href: "/poradnie/",
        title: "Poradnie",
        submenu: [
          {
            heading: "Podstawowa opieka zdrowotna",
            items: [
              { href: "/poradnie/poradnia-medycyny-rodzinnej/", title: "Poradnia medycyny rodzinnej" },
              { href: "/poradnie/poradnia-pediatryczna/", title: "Poradnia pediatryczna" }
            ]
          },
          {
            heading: "Poradnie specjalistyczne NFZ",
            items: [
              { href: "/poradnie/poradnia-laryngologiczna/", title: "Laryngologiczna" },
              { href: "/poradnie/poradnia-ginekologiczna/", title: "Ginekologiczna" },
              { href: "/poradnie/poradnia-reumatologiczna/", title: "Reumatologiczna" },
              { href: "/poradnie/poradnia-stomatologiczna/", title: "Stomatologiczna" },
              { href: "/poradnie/poradnia-urologiczna/", title: "Urologiczna" }
            ]
          },
          {
            heading: "Poradnie specjalistyczne prywatne",
            items: [
              { href: "/poradnie/poradnia-kardiologiczna/", title: "Kardiologiczna" },
              { href: "/poradnie/poradnia-medycyny-sportowej/", title: "Medycyny sportowej" },
              { href: "/poradnie/prywatna-poradnia-stomatologiczna/", title: "Stomatologiczna" },
              { href: "/poradnie/poradnia-chirurgiczna/", title: "Chirurgiczna" }
            ]
          }
        ]
      },
      {
        href: "/projekty-unijne/",
        title: "Projekty unijne",
        submenu: [
          { href: "/ddom/", title: "DDOM" },
          { href: "/projekty-unijne/informatyzacja/", title: "Informatyzacja" },
          { href: "/projekty-unijne/wypozyczalnia/", title: "Wypożyczalnia sprzętu" }
        ]
      },
      { href: "/apteki/", title: "Apteki", submenu: null },
      { href: "/kontakt/", title: "Kontakt", submenu: null }
    ]
    return (
      <>
        <header className="o-header">
          <div className="o-header__wrapper" data-g="container">
            <div className="a-logo">
              <a href="/"><img src="/uploads/logo.svg" alt="Logo" height={30}/></a>
            </div>
            <ul className="m-nav" data-g="">
              {
                items.map((item, key) => (
                  <li className="a-menu-item  a-menu-item--horizontal" key={key}>
                    <Link to={item.href}>
                      {item.title}
                      {item.submenu &&
                      <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L4 4L7 1" stroke="#1E2E2E" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      }
                      {item.submenu && !item.submenu[0].heading && (

                        <ul className="m-vertical-menu">
                          {
                            item.submenu.map((subitem, subkey) => (
                              <li className="a-menu-item  a-menu-item--vertical" key={subkey}>
                                <Link to={subitem.href}>{subitem.title}</Link>
                              </li>
                            ))
                          }
                        </ul>)
                      }
                      {item.submenu && item.submenu[0].heading && (
                        <div className={"wide-menu"}>
                          {item.submenu.map((deepMenu, key) => (
                            <div key={key}>
                              <h4>{deepMenu.heading}</h4>
                              <ul>
                                {
                                  deepMenu.items.map((subitem, subkey) => (
                                    <li className="a-menu-item  a-menu-item--vertical" key={subkey}>
                                      <Link to={subitem.href}>{subitem.title}</Link>
                                    </li>
                                  ))
                                }
                              </ul>
                            </div>
                          ))}
                        </div>
                      )}
                    </Link>
                  </li>
                ))
              }

              <li className="a-menu-telephone">
                <img src={withPrefix("images/layout/phone.svg")} alt="telefon" />
                13 433 03 28
              </li>
            </ul>
            <div className={`burger ${this.state.active ? "active" : ""}`} onClick={this.handleClick}
                 data-g="show hide@lg">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </header>

        <nav className={`o-mobile-nav ${this.state.active ? "active" : ""}`} data-g="show hide@lg">
          <ul className="m-nav" data-g={"container"}>
            {items.map((item, key) => (
              <li className="a-menu-item  a-menu-item--horizontal" key={key}>
                <a href={item.href}>{item.title}</a>
              </li>
            ))}
          </ul>
          <svg className={"decoration"} xmlns="http://www.w3.org/2000/svg" width="156" height="149"
               viewBox="0 0 156 149" fill="none">
            <circle cx="24" cy="125" r="24" fill="#404E7C" />
            <path d="M57 99C111.686 99 156 54.6857 156 -4.71837e-06L57 0L57 99Z" fill="#00B8BC" />
            <path d="M106.5 99C79.1571 99 57 121.157 57 148.5L156 148.5C156 121.157 133.843 99 106.5 99Z"
                  fill="#EAC4D5" />
          </svg>
        </nav>
      </>
    )
  }
}

export default Header
